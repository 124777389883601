<template>
  <Exhibition3D />
</template>

<script>

import Vue from 'vue';
import { Input, Button, Card, Loading, Select, Option } from 'element-ui';

import Exhibition3D from '@/components/Exhibition3D.vue';

export default {
  name: 'Test3D',
  components: {
    Exhibition3D,
    // 'el-input': Input,
    // 'el-button': Button,
    // 'el-card': Card,
    // 'el-select': Select,
    // 'el-option': Option,
  },
  data() {
    return {
    };
  }, // data
  mounted() {
  },
  watch: {
  },
  methods: {
  }, // methods
  computed: {
  }, // computed
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
